import { RouteRecordRaw } from 'vue-router';
import routesConfig from './routesConfig';
import CourseService from 'src/services/CourseService';
import { useGlobalStore } from 'src/stores/global-store';
import { useUserInfoStore } from 'src/stores/user-info-store';
import { useAuth } from 'src/composables/auth/useAuth';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: (to) => {
      const userInfoStore = useUserInfoStore();
      if (userInfoStore.type === 'student' ) {
        return { path: routesConfig.profile };
      }
      else if (userInfoStore.type === 'teacher' ) {
        return { path: routesConfig.course };
      }
      else {
        return { path: routesConfig.signin };
      }

    },
    component: () => import('layouts/MainLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: routesConfig.profileDetails,
        component: () => import('pages/StudentClassDetailsPage.vue'),
      },
      {
        path: routesConfig.course,
        component: () => import('pages/CoursePage.vue'),
      },
      {
        path: routesConfig.quiz,
        // component: () => import('pages/QuizPage.vue'),
        component: () => import('pages/QuizFoldersPage.vue'),
      },
      {
        path: routesConfig.folder,
        component: () => import('pages/FolderPage.vue'),
      },
      {
        path: routesConfig.quizEdit,
        component: () => import('pages/QuizEditPage.vue'),
      },
      {
        path: routesConfig.viewClass,
        component: () => import('pages/ViewClassPage.vue'),
      },
      {
        path: routesConfig.lessonHistory,
        component: () => import('pages/ViewClassLessonHistory.vue'),
      },
      {
        path: routesConfig.lessonDetails,
        component: () => import('pages/ViewLessonDetails.vue'),
      },
      {
        path: routesConfig.lessonDetailsReview,
        component: () => import('pages/ViewLessonReview.vue'),
      },
      {
        path: routesConfig.lessonDetailsRecording,
        component: () => import('pages/ViewLessonRecording.vue'),
      },
      {
        path: routesConfig.lessonDetailsTeacherFeedback,
        component: () => import('pages/ViewLessonFeedbackWrapper.vue'),
      },
      {
        path: routesConfig.courseMaterial,
        component: () => import('pages/ViewClassCourseMaterialPage.vue'),
      },
      {
        path: routesConfig.calendar,
        component: () => import('pages/CalendarView.vue'),
      },
      {
        path: routesConfig.homework,
        component: () => import('pages/HomeworkDashboardWrapper.vue'),
      },
      {
        path: routesConfig.homeworkAssign,
        component: () => import('pages/HomeworkAssignPage.vue'),
        beforeEnter: async (to, from, next) => {
          const userInfoStore = useUserInfoStore();
          if (userInfoStore.type !== 'teacher') {
            next({ path: '/404' });
            return;
          }
          next();
        }
      },
      {
        path: routesConfig.homeworkDetails,
        component: () => import('pages/HomeworkDetailsWrapper.vue'),
      },
      {
        path: routesConfig.homeworkEdit,
        component: () => import('pages/HomeworkEditPage.vue'),
        beforeEnter: async (to, from, next) => {
          const userInfoStore = useUserInfoStore();
          if (userInfoStore.type !== 'teacher') {
            next({ path: '/404' });
            return;
          }
          next();
        }
      },
      {
        path: routesConfig.submissionDetails,
        component: () => import('pages/SubmissionDetailsPage.vue'),
      },
      {
        path: routesConfig.homeworkEditSubmission,
        component: () => import('pages/HomeworkEditSubmissionPage.vue'),
        beforeEnter: async (to, from, next) => {
          const userInfoStore = useUserInfoStore();
          if (userInfoStore.type !== 'student') {
            next({ path: '/404' });
            return;
          }
          next();
        }
      },
    ],
  },
  {
    path: '/',
    redirect: (to) => {
      const userInfoStore = useUserInfoStore();
      if (userInfoStore.type === 'student' ) {
        return { path: routesConfig.profile };
      }
      else {
        return { path: routesConfig.course };
      }
    },
    component: () => import('layouts/FullSizeLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: routesConfig.profile,
        component: () => import('pages/StudentHomePage.vue'),
      },
      {
        path: routesConfig.diningRoom,
        component: () => import('pages/PetDiningRoomPage.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('layouts/FullSizeLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: routesConfig.viewClassStudent,
        component: () => import('pages/ViewClassStudentPage.vue'),
      },
    ],
    props: true,
    beforeEnter: async (to, from, next) => {
      const userInfoStore = useUserInfoStore();
      if (userInfoStore.type !== 'teacher') {
        next({ path: '/404' });
        return;
      }
      const courseId = to.params.courseId.toString();
      const studentId = to.params.studentId.toString();
      const globalStore = useGlobalStore();
      globalStore.pageLoading = true;

      try {
        const response = await CourseService.listStudents(courseId);
        response.forEach((student) => {
          if (student.id === studentId) {
            to.params.studentDetails = student;
            next();
            globalStore.pageLoading = false;
            return;
          }
        });
        next({ path: '/404' });
      } catch (error) {
        console.error(error);
        next({ path: '/404' });
      }

      globalStore.pageLoading = false;
    }
  },
  {
    path: routesConfig.meeting,
    component: () => import('pages/MeetingPage.vue'),
    meta: { layout: 'no-layout', requiresAuth: true },
  },
  {
    path: routesConfig.bbbRedirect,
    component: () => import('pages/RedirectPage.vue'),
    meta: { layout: 'no-layout' },
  },
  {
    path: routesConfig.signin,
    name: 'signin',
    component: () => import('pages/auth/LoginPage.vue'),
    meta: { layout: 'no-layout', redirectIfAuth: true },
  },

  {
    path: routesConfig.verifyEmailMessage,
    component: () => import('pages/auth/EmailVerificationMessage.vue'),
    meta: { layout: 'no-layout' },
  },

  {
    path: routesConfig.signup,
    name: 'signup',
    component: () => import('pages/auth/RegisterPage.vue'),
    meta: { layout: 'no-layout', redirectIfAuth: true },
  },

  {
    path: routesConfig.resetPassword,
    name: 'resetPassword',
    component: () => import('pages/auth/ResetPasswordPage.vue'),
    meta: { layout: 'no-layout', redirectIfAuth: true },
  },

  {
    path: routesConfig.resetPasswordVerify,
    name: 'resetPasswordVerify',
    component: () => import('pages/auth/ResetPasswordVerifyPage.vue'),
    meta: { layout: 'no-layout' },
  },

  {
    path: routesConfig.emailVerification,
    name: 'emailVerification',
    component: () => import('pages/auth/EmailVerificationPage.vue'),
    meta: { layout: 'no-layout' },
  },

  {
    path: routesConfig.termsAndConditions,
    component: () => import('pages/auth/TermsAndConditionsPage.vue'),
    meta: { layout: 'no-layout' },
  },

  {
    path: '/404',
    component: () => import('pages/ErrorNotFound.vue'),
  },

  {
    path: '/:catchAll(.*)*',
    redirect: '/404',
  },
];

export default routes;
