import { route } from 'quasar/wrappers';
import { inject } from 'vue';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';
import routes from './routes';
import AuthService from 'src/services/AuthService';
import routesConfig from './routesConfig';
import logger from "src/logger";
import { UserInfoStore, AuthStore } from 'src/types';
import { useQuasar } from 'quasar'

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  Router.beforeResolve( async (to, from) => {
    const userInfoStore = inject('userInfoStore') as UserInfoStore; 
    const authStore = inject('authStore') as AuthStore; 
    const $q = useQuasar();

    logger.debug({
      userId: userInfoStore.id,
      isEmailVerified: userInfoStore.is_email_verified,
      isAuthenticated: authStore.isAuthenticated,
      to: to.path,
      from: from.path
    }, 'User navigating - resolve route');
    console.log('User navigating - resolve route', 'isAuthenticated:', authStore.isAuthenticated,'isEmailVerified',userInfoStore.is_email_verified,'to:', to.path,to.name,'from:', from.path, 'has query', to.query.invite)

    if (to.meta.requiresAuth && !authStore.isAuthenticated) {
      console.log('requiresAuth')
      logger.debug({ path: to.path, isAuthenticated: authStore.isAuthenticated}, 'Redirect to login due to unauthenticated access attempt');
      // Maintain the invite query if present
      return {
          path: to.query.invite ? routesConfig.signup : routesConfig.signin,
          query: to.query.invite ? { invite: to.query.invite } : { redirect: to.fullPath }
      };
  }
    
    // Handle authenticated state
    if (authStore.isAuthenticated && userInfoStore.id) {
      console.log('authenticated')
        // allow users who just signed up sign up again, avoid loop
        if (!userInfoStore.is_email_verified && to.path === routesConfig.signup && to.query.invite) {
          return true
        }
        // allow users who just signed up to see verify email message
        if (!userInfoStore.is_email_verified && to.path === routesConfig.verifyEmailMessage && from.path === routesConfig.signup) {
          return true
        }
        if (!userInfoStore.is_email_verified && to.path !== routesConfig.verifyEmailMessage && to.name !== 'emailVerification') {
          console.log('authenticated 1')
            logger.debug({ userId: userInfoStore.id, isEmailVerified: userInfoStore.is_email_verified }, 'Authenticated user with unverified email');
            return {
                path: routesConfig.verifyEmailMessage,
                query: to.query
            };
        }
        if (to.query.invite) {
          console.log('authenticated 2');
          logger.debug({ path: to.path, invite: to.query.invite }, 'Authenticated user including invite');
          AuthService.enrollStudent(userInfoStore.id, to.query.invite.toString())
            /*.catch((error) => {
              $q.notify({
                type: 'negative',
                message: error.response?.data?.error,
              });
            });*/
          return { path: routesConfig.home };
      
          
      }

         // Handle redirection for authenticated users on redirectIfAuth pages
        if (to.meta.redirectIfAuth) {
          console.log('authenticated 3')
            if (authStore.isAuthenticated) {
                logger.debug({ path: to.path }, 'Redirect to home due to redirectIfAuth');
                console.log('Redirect to home due to redirectIfAuth' )
                return { path: routesConfig.home, query: to.query };
            }
        }
        return true;
    }

    if (!authStore.isAuthenticated && to.path === routesConfig.verifyEmailMessage) {
      console.log('authenticated 4')
      logger.debug({ path: to.path }, 'Redirect to login due to unauthenticated access attempt');
      return { path: routesConfig.signin };
    }
  console.log('else')
  return true; 
});

  return Router;
});