import { api } from 'boot/axios';
import apiConfig from 'src/apiConfig';
import { Cookies } from 'quasar';
import 
 {LoginData,
  LogoutData,
  LoginResponse,
  LogoutResponse,
  TokenResponse,
  RefreshResponse,
  RegisterData,
  RegisterResponse,
  UserInfoResponse,
  UserInfo,} 
  from '../types';

/**
 * class for making backend API calls related to authentication
 */
class AuthService {

  getRefreshToken() {
    return Cookies.get('refresh_token');
  }
  
  /**
   * Login a user
   * @param {String} username
   * @param {String} password
   * @returns {String} Access token
   */
  async login(loginData: LoginData): Promise<LoginResponse>  {
      try {
        const { data } = await api.post<TokenResponse>(apiConfig.auth.login, loginData, { withCredentials: true });
        return [null, data];
      } catch (error: any) {
        return [error]
      }
    }

  async register(userData: RegisterData): Promise<RegisterResponse>  {
    try { 
      const { status } = await api.post(apiConfig.auth.signUp, userData);
      return [null, { status }];
    } catch (error: any) {
      return [error];
    }
  }

  /**
   * Logout
   *
   * This API call removes the HttpOnly refresh token cookie to logout the current logged in user
   */
  async logout(): Promise<LogoutResponse> {
    try {
      const { data } = await api.post<LogoutData>(apiConfig.auth.logout);
      return [null, data];
    } catch (error: any) {
      return [error]
    }
  }

  /** 
  * Refresh token
  */
  async refreshToken(): Promise<TokenResponse> {
    try {
      const { data } = await api.post<TokenResponse>(apiConfig.auth.refresh);
      return data; 
    } catch (error) {
      throw error;
    }
  }

  async sendTimezone() {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await api.post(apiConfig.auth.sendTimezone, {
        timezone: timezone,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async readPetLetter() {
    try {
      const response = await api.post(apiConfig.auth.readPetLetter);
      return response;
    } catch (error) {
      throw error;
    }
  }

 async getUserInfo(): Promise<UserInfoResponse> {
  try {
    const { data } = await api.get<UserInfo>(apiConfig.auth.userInfo);
    return [null, data];
  } catch (error: any) {
    return [error]
  }
 }
  async getUserEmailVerificationInfo(): Promise<{
    email: string | null;
    isVerified: boolean;
  }> {
    try {
      const response = await api.get(apiConfig.auth.emailVerificationCheck);
      return {
        email: response.data.email,
        isVerified: response.data.is_verified,
      };
    } catch (error) {
      throw error;
    }
  }
  
  async enrollStudent(userId: string, token: string) {
    try {
      const response = await api.post(apiConfig.auth.enroll, {
        user_id: userId,
        token: token,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendVerifyEmail () {
    try {
      const response = await api.post(apiConfig.auth.sendVerifyEmail, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendResetEmail(email: string) {
    try {
      const response = await api.post(
        apiConfig.auth.resetPasswordRequest,
        {
          email: email,
        },
        { headers: {} }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async resetEmailVerify(token: string | string[]) {
    try {
      const response = await api.post(
        apiConfig.auth.resetPasswordVerify,
        {
          token: token,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async resetSetPassword(token: string | string[], password: string) {
    try {
      const response = await api.post(apiConfig.auth.resetPassword, {
        token: token,
        password: password,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async verify(token: string | string[]) {
    try {
      const response = await api.post(apiConfig.auth.verifyEmail, {
        token: token,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async setOnboarding() {
    try {
      const response = await api.post(apiConfig.auth.setOnboarding);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new AuthService();
