import AuthService from 'src/services/AuthService';
import { useUserInfoStore } from 'src/stores/user-info-store';
import { useAuthStore } from 'src/stores/auth-store';
import { RegisterData } from 'src/types';
import { ref, computed } from 'vue';
import { H } from 'highlight.run';
import logger from 'src/logger';

const accessToken = ref('');

export function useAuth() {
  const userInfoStore = useUserInfoStore();
  const authStore = useAuthStore();

  const isAuthenticated = computed(() => {
    const authenticated = accessToken.value;
    return !!authenticated;
  });

  /*watch(isAuthenticated, (newVal) => {
    console.log('isAuthenticated', newVal);
    authStore.setIsAuthenticated(newVal);
  }, { immediate: true });
*/

  const refreshToken = async (
    initial: boolean
  ): Promise<{ success: boolean; accessToken?: string; message?: string }> => {
    try {
      const data = await AuthService.refreshToken();
      if (data && data.access) {
        accessToken.value = data.access;
        authStore.setIsAuthenticated(true);

        if (initial) {
          await userInfoStore.fetchUserInfo();
        }

        return { success: true, accessToken: data.access };
      } else {
        throw new Error('No access token returned');
      }
    } catch (error: any) {
      logger.error({ error: error.message }, 'Error refreshing token');
      authStore.setIsAuthenticated(false);
      throw error;
    }
  };

  const login = async (email: string, password: string): Promise<void> => {
    try {
      const [error, data] = await AuthService.login({
        email: email.toLowerCase(),
        password: password,
      });
      if (error) {
        const errorMessage =
          error.response?.data?.detail || 'Login failed. Please try again.';
        logger.error({ error: errorMessage }, 'Login error');
        return errorMessage;
      }
      if (data) {
        logger.info(
          { email, userId: userInfoStore.id, status: 'Logged in' },
          'User logged in'
        );
        accessToken.value = data.access;
        authStore.setIsAuthenticated(true);
      }

      await userInfoStore.fetchUserInfo();
      /*if (process.env.VITE_ENV === 'prod') {
        H.identify(userInfoStore.email, {
          id: userInfoStore.id,
        });
      }*/

      AuthService.sendTimezone();
    } catch (error: any) {
      logger.error({ error: error.message }, 'Login process failed');
      authStore.setIsAuthenticated(false);
      throw error;
    }
  };

  const clearUserInfo = () => {
    authStore.setIsAuthenticated(false);
    accessToken.value = '';
    userInfoStore.clearUserInfo();
  };

  const logout = async (): Promise<void> => {
    try {
      const [error, data] = await AuthService.logout();
    } catch (error: any) {
      logger.error(
        { error: error.message },
        'Error during logout | Refresh token not set'
      );
    } finally {
      clearUserInfo();
    }
  };

  const register = async (userData: RegisterData): Promise<void> => {
    try {
      userData.email = userData.email.toLowerCase();
      const [error, data] = await AuthService.register(userData);
      if (error) {
        const errorMessage =
          error.response?.data?.error ||
          'Registration failed. Please try again.';
        logger.error({ error: errorMessage }, 'Registration error');
        return errorMessage;
      }
      if (data) {
        accessToken.value = data.access;
        authStore.setIsAuthenticated(true);
        logger.info(
          { email: userData.email, status: 'Registered' },
          'User registered.'
        );
      }

      await userInfoStore.fetchUserInfo();
      AuthService.sendTimezone();
    } catch (error: any) {
      logger.error({ error: error.message }, 'Registration process failed');
      logger.debug(
        { userData: userData, isAuthenticated: authStore.isAuthenticated },
        'Registration process failed'
      );
      throw error;
    }
  };

  const removeAccessToken = () => {
    accessToken.value = '';
    console.log('removeAccessToken', accessToken.value);
    userInfoStore.clearUserInfo();
    //authStore.setIsAuthenticated(false);
  };

  return {
    login,
    refreshToken,
    accessToken,
    isAuthenticated,
    logout,
    register,
    clearUserInfo,
    removeAccessToken,
  };
}
